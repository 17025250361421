<template>
  <div v-if="info&&info.pName">    
    <div class="about" v-if="info">
    <Panel header="项目信息">
        <div >  
        <div class="div_33_100 margin_top"> 
          <div class="p-inputgroup flex-1 ">        
            <span class="p-float-label">
                <InputText id="pName" v-model="info.pName" :disabled="isEdit" />
                <a :href="'/projects/'+info.pId"><Button icon="pi pi-file-edit" style="margin: 0.1em;position: fixed;right: 1em;bottom: 8em;z-index: 99;border-radius: 50% 50%;width: 3em;height: 3em;"/></a> 
                <label for="pName">项目名称</label>
            </span>             
          </div>
        </div>  
          

        <div class="div_33_100 margin_top"> 
          <div class="p-inputgroup flex-1 ">        
            <span class="p-float-label">
                <InputText id="username" v-model="info.pLine" :disabled="isEdit" />
                <label for="username">产品线</label>
            </span>             
          </div>
        </div>
        
        <div class="div_33_100 margin_top">           
          <div class="p-inputgroup flex-1 ">        
            <span class="p-float-label">
                <InputText id="uPhone"  :disabled="isEdit" v-model="info.pStyle"/>
                <label for="uPhone">产品类型</label>
            </span>             
          </div>
        </div>

        
        <div class="div_33_100 margin_top">
          <div class="p-inputgroup flex-1 ">        
            <span class="p-float-label">
                <InputText id="uDegree"  :disabled="isEdit" v-model="info.pStatus"/>
                <label for="uDegree">状态</label>
            </span>             
          </div>
        </div>
        
        <div class="div_33_100 margin_top"> 
          <div class="p-inputgroup flex-1 ">        
            <span class="p-float-label">
                <InputText id="uTonwn"  :disabled="isEdit" v-model="JSON.parse(info.pGroupInfo).uGroupName"/>
                <label for="uTonwn">所属小组</label>
            </span>             
          </div>
        </div>

        <div class="div_33_100 margin_top"> 
          <div class="p-inputgroup flex-1 ">        
            <span class="p-float-label">
                <InputText id="uCompany"  :disabled="isEdit" v-model="JSON.parse(info.pGroupInfo).uCompanyName"/>
                <label for="uCompany">所属公司</label>
            </span>             
          </div>
        </div>

        <div class="div_33_100 margin_top"> 
          <div class="p-inputgroup flex-1 ">        
            <span class="p-float-label">
                <InputText id="uCompany"  :disabled="isEdit" v-model="info.pCreator"/>
                <label for="uCompany">创建人</label>
            </span>             
          </div>
        </div>

        <div class="div_33_100 margin_top"> 
          <div class="p-inputgroup flex-1 ">        
            <span class="p-float-label">
              <Calendar id="uBirthday" v-model="info.CreateTime"  :disabled="isEdit"/>  
              <label for="uBirthday">开始时间</label>
            </span>             
          </div>
        </div>

        <div class="div_33_100 margin_top"> 
          <div class="p-inputgroup flex-1 ">        
            <span class="p-float-label">
              <Calendar id="uBirthday" v-model="info.pStopDate"  :disabled="isEdit"/>  
              <label for="uBirthday">开始时间</label>
            </span>             
          </div>
        </div>

        
        <div class="div_33_100 margin_top"> 
          <div class="p-inputgroup flex-1 ">        
            <span class="p-float-label">
              <Calendar id="uGdday" v-model="info.pMatainDate"  :disabled="isEdit"/>  
              <label for="uGdday">维护时间</label>
            </span>             
          </div>
        </div>

        
        
        <div style="clear:both;"></div>
        <div class="place1em"></div>
      </div>
      <div>
        
        
      </div>
        
    </Panel>
    <Panel header="项目描述">
      <div :innerHTML="info.pDescription" style="text-align: left;line-height: 2em;font-family: Arial, Helvetica, sans-serif;" class="line-numbers"></div> 
      
    </Panel>
    <div class="place1em"></div>
    <Panel header="项目进度">
      <FireTimeLine></FireTimeLine>
    </Panel>
    <div class="place1em"></div>
    <Panel header="执行计划">
      <CommonPlan :isparent="-1"  ref="Finished" pstyle="ProjectPlan" pheader="增加计划"  :belonginfo="belonginfo" :setroledata="setroledata" ></CommonPlan>
    </Panel>
    <div class="place1em"></div>
    <Panel header="项目资料">      
      <div class="clear">
        <FileUpload mode="basic" name="demo[]" :maxFileSize="1024000000"
                :multiple="true" :auto="true" chooseLabel="上传附件" customUpload @uploader="customFileUploader" />        
      </div>
      <div class="div_33_100" v-for="{fId,fDisplayName,fDloadName} in projfiles" :key="fId">
        <div style="padding: 1em;">
          <div>{{fDisplayName}}</div>
          <div>
            <div style="float: left;width: 50%;cursor: pointer;">
              <i class="fas fa-download margin" @click="useApi.downloadfetch('api/File/' + fId, fDloadName)"></i>
            
            </div>
            <div style="float: left;width: 50%;cursor: pointer;">
              <i class="fas fa-times margin" @click="delete_blog({fId:fId,fDisplayName:fDisplayName})"></i>
            
            </div>
            <div class="clear"></div>
          </div>
         

        </div>
      </div>
      <div class="clear"></div>
    </Panel>
    <div class="place1em"></div>
    <Panel header="问题单">
      <CommonPlan :isparent="-1"  ref="Finished" pstyle="ProjectQuestion" pheader="增加问题"  :belonginfo="belonginfo" :setroledata="setroledata" ></CommonPlan>
    </Panel>
    <div class="place1em"></div>
    <Panel header="项目统计">
      <div class="div_33_100"><FireChart></FireChart></div>
      <div class="div_33_100"><FireChart></FireChart></div>
      <div class="div_33_100"><FireChart></FireChart></div>
      <div class="clear"></div>
      
    </Panel>
    
    </div>
  </div>
</template>

<script setup>
import { onMounted,ref,defineProps } from 'vue';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Panel from 'primevue/panel';
import FireTimeLine from '@/components/FireTimeLine.vue';
import FireChart from '@/components/FireChart.vue';
import CommonPlan from '@/components/CommonPlan.vue';
import useSlade from '@/services/useSlade';
import FileUpload from 'primevue/fileupload';
import useApi from '@/services/useApi';


const props = defineProps({
      project_id: String,       
      planheader: String
  })
var isEdit=ref(true)
var info=ref({});
let belonginfo=ref({})

let projfiles=ref([])
const get_projects=async()=>{
  info.value.pId=props.project_id
  info.value =await useSlade.get_project(info.value)
  console.log(info.value)
  belonginfo.value.pId=info.value.pId;
  belonginfo.value.pName=info.value.pName;
  setroledata.value={rdRange:{rdRange: '仅自己可见' },wrRange:{ wrRange: '仅自己编辑' },wrUsers:[],rdUsers:[],
  sShareGroup:"{\"Id\":"+JSON.parse(info.value.pGroupInfo).Id+",\"uGroupName\":\""+JSON.parse(info.value.pGroupInfo).uGroupName+"\",\"uCompanyName\":\""+JSON.parse(info.value.pGroupInfo).uCompanyName+"\"}"}
  getpgfiles();
  document.title = '['+info.value.pName+']-[项目]-[碳硅锗兮]';
  document.querySelector('meta[name="keywords"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
  document.querySelector('meta[name="description"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
}

const getpgfiles=async()=>{
  let searchobg={fBelongInfo:'project_' + props.project_id}
  projfiles.value=await useSlade.get_pjfiles(searchobg)
} 

let setroledata=ref({})

onMounted(()=>{
  get_projects();
   
})

const delete_blog = async (postgroup) => {
  await useSlade.del_file(postgroup);
  getpgfiles();
}

const customFileUploader = async (event) => {
  await useApi.uploadfile('api/File/project_' + props.project_id, event);
  getpgfiles();
};


</script>