<template>
    
    <Dialog v-model:visible="is_upload" header="上传规格书" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"> 
        <datasheet_upload_object @ondatachange="listshow()"></datasheet_upload_object>
    </Dialog>

    <Button icon="pi pi-map" @click="is_styleon=!is_styleon" v-if="authed"
        style="margin: 0.1em;position: fixed;right: 1em;bottom: 11.5em;z-index: 99;border-radius: 50% 50%;width: 3em;height: 3em;" />
    <Dialog v-model:visible="is_styleon" header="维护类型" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"> 
        <datasheet_style @ondatachange="listshow()"></datasheet_style>
    </Dialog>

    <Button icon="pi pi-users" @click="is_saleon=!is_saleon" v-if="authed"
        style="margin: 0.1em;position: fixed;right: 1em;bottom: 15em;z-index: 99;border-radius: 50% 50%;width: 3em;height: 3em;" />
    <Dialog v-model:visible="is_saleon" header="维护厂家联系方式" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"> 
        <datasheet_user @ondatachange="listshow()"></datasheet_user>
    </Dialog>
    
    <div v-if="!isShow">
        
        <div v-if="!isEdit">
            <Button icon="pi pi-plus" @click="is_upload=!is_upload" v-if="authed" style="margin: 0.1em;position: fixed;right: 1em;bottom: 8em;z-index: 99;border-radius: 50% 50%;width: 3em;height: 3em;" />            
            <div style="height: 1.5em;"></div>
            <div>
                <SearchBlock :sname="'规格书'" @onsearch="filters" :flabel="filter_arr"></SearchBlock>
            </div>
            <div class="padding" style="min-height: 1500px;">
                <div v-for="blog in blogs" :key="blog.Id" style="padding: 0.5em;position: relative;" class="div_50_100">                    
                    <datasheet_show_object :in_object="blog">
                        <template v-slot:footer>
                            <i class="pi pi-trash" @click="del_datasheet(blog)" v-if="authed" style="margin: 0.1em;border-radius: 50% 50%;width: 2em;height: 2em;position: absolute;right: 1em;top:1em;cursor: pointer;" />
                            <a :href="'/datasheet/show/' + blog.Id" target="_blank"><Button icon="pi pi-eye"  v-if="authed" style="margin: 0.1em;border-radius: 50% 50%;width: 2em;height: 2em;" /></a>
                            <ToggleButton onIcon="pi pi-edit" offIcon="pi pi-pencil" on-label="" off-label="" class="w-9rem"
                            @click="blog.is_edit=(blog.is_edit)?false:!blog.is_edit" v-if="authed" style="margin: 0.1em;border-radius: 50% 50%;width: 2em;height: 2em;"/>
                            <a><Button icon="pi pi-download"  v-if="authed" style="margin: 0.1em;border-radius: 50% 50%;width: 2em;height: 2em;"
                                 @click="useApi.downloadapi('slade/getds/'+blog.Id,blog.FileName)" /></a>
                        </template>
                    </datasheet_show_object>
                    <div class="place1em"></div>
                    <Dialog v-model:visible="blog.is_edit" header="修改器件" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"> 
                        <datasheet_modify_object @ondatachange="listshow" :in_object="blog"></datasheet_modify_object>
                    </Dialog>
                    
                </div>
                <div class="clear"></div>

            </div>
        </div>
        
    </div>
    
</template>
    
<script setup>
import useApi from "@/services/useApi";
import { ref, onMounted, defineProps } from "vue";
import Button from 'primevue/button';
import ToggleButton from 'primevue/togglebutton';
import { useStore } from "vuex";
import SearchBlock from "@/components/SearchBlock.vue";
import useSlade from '@/services/useSlade';
import useFree from '@/services/useFree';
import datasheet_show_object from '@/components/device/datasheet_show.vue'
import datasheet_upload_object from '@/components/device/datasheet_upload.vue'
import datasheet_modify_object from '@/components/device/datasheet_modify.vue'
import datasheet_style from '@/components/device/datasheet_style.vue';
import datasheet_user from '@/components/device/datasheet_user.vue';
import Dialog from 'primevue/dialog';
const props = defineProps({
    filter: String,
    project_id: String
})
let isEdit = ref(false)
let isShow = ref(false)
let showid = ref(0)
const store = useStore();
let authed = ref(store.state.authed);
let blogs = ref([])
let local_blogs = ref([])
let is_upload=ref(false)
let is_styleon=ref(false)
let is_saleon=ref(false)

const filters = (name, type) => {
    blogs.value = useApi.search_item(name, type, local_blogs.value)
}
const filter_arr = [{ name: '名称', type: 'Name' }, { name: '大类型', type: 'Style1' }, { name: '小类型', type: 'Style2' }, { name: '描述', type: 'Description' }, { name: '厂家', type: 'Manufacture' }]
const listshow = async () => {
    if (store.state.authed)
        blogs.value = await useSlade.get_allds()
    else
        blogs.value = await useFree.get_allds()
    
    local_blogs.value = blogs.value

}

const del_datasheet = async (obj) => {
   await useSlade.del_ds(obj);
   listshow()
}




onMounted(() => {
    listshow();
    if (props.filter && props.filter.length > 0) {
        filters()
    }
    if (props.project_id) {        
        isShow.value = true;
        showid.value = props.project_id
    }
    
    document.title = '[元器件手册]-[碳硅锗兮]';
    document.querySelector('meta[name="keywords"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
    document.querySelector('meta[name="description"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
})
</script>
    