<template>
  <div style="height: 1.5em;">

  </div>
  <div>
    <SearchBlock :sname="'笔记'" :tkey1="'bContent'" :tkey2="'bsContent'" :tkeyname="'bName'"
      :tkeyurl="'bId'" @onsearch="filters" :flabel="filter_arr"></SearchBlock>
  </div> 
  <div style="position: relative;">
    <Dialog v-model:visible="ismenu_show" header="目录" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"> 
        <CommonPlan :isparent="-1"  ref="OnGoing" pstyle="Blogs" pheader="增加笔记"></CommonPlan>
    </Dialog>
    <div class="padding">
      <div class="blog_outline" v-for="{ Id, bName, bDescription, bReadCount, bImgUrl, CreateTime, bMsgCount,bOrderSN } in blogs"
        :key="Id" style="position:relative">
        <div v-if="blogs && blogs.length > 0" class="blog">
          <div :style="'background-image:' + bImgUrl" class="blog_img">

            <!-- <img :src=" (bImgUrl&&bImgUrl.length>0)?bImgUrl.replace('url(','').replace(')',''):''" top alt="..." style="width: 100%;height: 100%;z-index: -99;"/> -->
          </div>
          <div style="padding: 0.5em;text-align: left;">
            <div style="height: 3em;"><a :href="(bOrderSN)?'/nblogs/blog_' + bOrderSN:''" @click="page_url = Id" style="font-weight: bold;color: #4d4b4b;">{{ (bName) }}</a></div> 
            <div v-if="!(props.type && props.type == 'diary')" style="height: auto;text-align:left;color: #a6a2a2;font-size:0.8em !important;overflow:hidden;height: 4em;">
              {{ bDescription }}
            </div>
            <div style="padding: 0.5em;font-size: 15px !important;">
              <a v-if="false" style="margin:0 2em 0 0em"><i class="fas fa-trash" :title="'删除' + Id" style="cursor: pointer;"
                  @click="delete_blog(bId)"></i></a>
              <a v-if="false" style="margin:0 2em 0 2em" :href="'/blogs/write/' + Id"><i style="cursor: pointer;   "
                  class="fas fa-edit" :title="'编辑' + bId"></i></a>
              <a v-if="false" style="margin:0 0em 0 2em" :href="'/blogs/map'"><i style="cursor: pointer;  "
                  class="fas fa-map" :title="'编辑' + bId"></i></a>
            </div>
          </div>
          <div style="padding: 0.5em;">
            <div>
              <div style="float: left;width: 7em;font-size: 15px !important;">
                <small class="text-muted"><i class="fas fa-clock-o"></i> {{ CreateTime.slice(0, 10) }} </small>
              </div>
              <div style="float: left;width: 5em;font-size: 15px !important;">
                <small class="text-muted"> <i class="fas fa-eye"></i> {{ useCommon.numtostirng(bReadCount) }} </small>
              </div>
              <div style="float: left;width: 5em;font-size: 15px !important;">
                <small class="text-muted"> <i class="fas fa-comment"></i> {{useCommon.numtostirng( bMsgCount) }} </small>
              </div>

              <div style="clear: both;"></div>
            </div>
          </div>

        </div>
      </div>
      <div class="clear"></div>
      <div style="height: 3em;" id="getnewblogs"></div>      
    </div>
    <a title="笔记目录"><Button icon="pi pi-bars" @click="ismenu_show = !ismenu_show"  style="margin: 0.1em;position: fixed;right: 1em;bottom: 8em;z-index: 99;border-radius: 50% 50%;width: 3em;height: 3em;" /></a>
  </div>
  
</template>

<script setup>
import SearchBlock from "@/components/SearchBlock.vue";
import CommonPlan from '@/components/CommonBlogMenu.vue';
import useApi from "@/services/useApi";
import { ref, onMounted, defineProps } from "vue";
import Button from 'primevue/button';
import useSlade from "@/services/useSlade";
import useCommon from "@/services/useCommon";
import useSilkroadInfo from "@/services/useSilkroadInfo";
import Dialog from 'primevue/dialog';
const props = defineProps({
  filter: String,
  type: String
})

let blogs = ref([])
let blog_offset=0
let local_blogs = ref([])
let filter_arr = ref([{ name: '标题', type: 'bName' }, { name: '描述', type: 'bDescription' }, { name: '主题', type: 'bsContent' }])
let ismenu_show = ref(false)
const filters = (name, type) => {
  blogs.value = useApi.search_item(name, type, local_blogs.value)
}
const listshow = async (blog_len) => {

  if (props.type && props.type == "diary") {
    useSlade.get_diarys().then(res => {
      blogs.value = res
      local_blogs.value = res
    })
  }
  else {    
    let blog={bId:blog_len}
    useSlade.get_blogs(blog).then(res => {
      blogs.value=blogs.value.concat(res)
      local_blogs.value=local_blogs.value.concat(res)
      blog_offset=blog_offset+12
    })
  }
  local_blogs.value = blogs.value
}


onMounted(() => {
  listshow(blog_offset);
  window.addEventListener("scroll", scrollHandle, true); // 监听 监听元素是否进入/移出可视区域
  console.log("addEventListener scrollHandle")
  if (props.filter && props.filter.length > 0) {
    filters()
  }
  if(!(props.type))
  {
    useSilkroadInfo.getpa
    useSilkroadInfo.pagename(document,"[文章列表]")
  }
  document.title = '[笔记列表]-[碳硅锗兮]';
})

let is_getdata=false
const scrollHandle=()=> {
      var blog_handler=document.getElementById('getnewblogs')
      if(blog_handler)
      {
          const offset = blog_handler.getBoundingClientRect();
          const offsetBottom = offset.bottom;
          //console.log(offset.height ,window.innerHeight  ,offsetBottom)
          if(offsetBottom<(window.innerHeight*0.8))
          {
            if(!is_getdata){
              listshow(blog_offset);
              //console.log(blog_offset)
              is_getdata=true
            }
          }
          if(offsetBottom>window.innerHeight){
            is_getdata=false
          }

      }
      else
      {
        window.removeEventListener("scroll", scrollHandle, true); 
        console.log("remove scrollHandle")
      }
  }
</script>
