<template>
  <div>
    <div v-if="is_menushow">
      <!-- Navbar-->
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid justify-content-between" style="width: 1280px;">
          <!-- Left elements -->
          <div class="d-flex">
            <!-- Brand -->
            <router-link class="navbar-brand me-2 mb-1 d-flex align-items-center" :to="blogurl">
              <img style="height: 2em;width: 2em;border-radius: 50% 50%;" src="/img/logo1.png" />
              碳硅锗兮
            </router-link>

            <!-- Search form -->

          </div>
          <!-- Left elements -->

          <!-- Center elements -->
          <ul class="navbar-nav flex-row d-none d-md-flex">
            <li class="nav-item me-3 me-lg-1 active">
              <router-link class="nav-link" :to="blogurl">
                <span><i class="fas fa-book fa-lg"></i> 笔记 </span>
              </router-link>
            </li>

            <li class="nav-item me-3 me-lg-1">
              <router-link class="nav-link" to="/fileshare">
                <span><i class="fas fa-folder fa-lg"></i> 资源 </span>

              </router-link>
            </li>
            <li class="nav-item me-3 me-lg-1">
              <router-link class="nav-link" to="/datasheet/show">
                <span><i class="fas fa-paperclip fa-lg"></i> 器件库 </span>

              </router-link>
            </li>
            <li class="nav-item me-3 me-lg-1">
              <router-link class="nav-link" to="/device/show">
                <span><i class="fas fa-blog fa-lg"></i> 物料表</span>

              </router-link>
            </li>
            <li class="nav-item me-3 me-lg-1">
              <router-link class="nav-link" to="/projects">
                <span><i class="fas fa-blog fa-lg"></i> 项目库 </span>

              </router-link>
            </li>

            <li class="nav-item dropdown me-3 me-lg-1">
              <router-link class="nav-link dropdown-toggle hidden-arrow" to="/factory" id="navbarDropdownMenuLink"
                role="button" data-mdb-toggle="dropdown" aria-expanded="false">
                <i class="fas fa-car fa-lg"></i> 数据坊

              </router-link>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                <li>
                  <router-link class="dropdown-item" to="/fileshare"> <i class="fas fa-car fa-lg"></i> <span
                      style="margin-right: 1em;"> 资料包 </span>
                    <span class="badge rounded-pill badge-notification bg-danger">12</span></router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/myself"> <i class="fas fa-car fa-lg"></i> <span
                      style="margin-right: 1em;"> 事务所 </span>
                    <span class="badge rounded-pill badge-notification bg-danger">12</span></router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/cell/show"> <i class="fas fa-car fa-lg"></i> <span
                      style="margin-right: 1em;"> 选型池 </span>
                    <span class="badge rounded-pill badge-notification bg-danger">12</span></router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/app/show"> <i class="fas fa-car fa-lg"></i> <span
                      style="margin-right: 1em;"> 小程序 </span>
                    <span class="badge rounded-pill badge-notification bg-danger">12</span></router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/checklist/show"> <i class="fas fa-car fa-lg"></i> <span
                      style="margin-right: 1em;"> 经验树 </span>
                    <span class="badge rounded-pill badge-notification bg-danger">12</span></router-link>
                </li>
              </ul>
            </li>


            <li class="nav-item me-3 me-lg-1" v-if="cmune">
              <router-link class="nav-link" to="/bloglist">
                <span><i class="fas fa-blog fa-lg"></i> 广告墙 </span>

              </router-link>
            </li>
          </ul>
          <!-- Center elements -->

          <!-- Right elements -->
          <ul class="navbar-nav flex-row">
            <li class="nav-item me-3 me-lg-1">
              <router-link class="nav-link d-sm-flex align-items-sm-center" :to="url">
                <img src="/img/user03.jpg" class="rounded-circle" height="22" alt="Black and White Portrait of a Man" />
                <strong class="d-none d-sm-block ms-1">{{ (myusername != '') ? `${myusername}` : "Login" }}</strong>

              </router-link>
            </li>


            <div v-if="myusername != ''" >
              <li class="nav-item dropdown me-3 me-lg-1">
                <router-link class="nav-link dropdown-toggle hidden-arrow" to="#" id="navbarDropdownMenuLink"
                  role="button" data-mdb-toggle="dropdown" aria-expanded="false">
                  <i class="fas fa-navicon fa-lg"></i>
                </router-link>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                  <div class="cmd_btn">
                    <li>
                      <router-link class="dropdown-item" :to="blogurl">笔记</router-link>
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/myself">事务所</router-link>
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/projects">项目库</router-link>
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/logout">资源池</router-link>
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/logout">广告墙</router-link>
                    </li>
                  </div>
                  <li>
                    <router-link class="dropdown-item" to="#">消息中心</router-link>
                  </li>

                  <li>
                    <router-link class="dropdown-item" to="/logout">退出登录</router-link>
                  </li>


                </ul>
              </li>
            </div>

          </ul>
          <!-- Right elements -->
        </div>

      </nav>

      <!-- Navbar -->

    </div>
    <div style="height: 2em;">


    </div>
    <div>
      <div class="middle">
          <div>
            <Toast position="top-center" />
            <router-view />
          </div>
        
        
      </div>
    </div>
    <div>                  
      <a @click="scrollToTop" title="返回顶部"><Button icon="pi pi-arrow-up" style="margin: 0.1em;position: fixed;right: 1em;bottom: 1em;z-index: 97;border-radius: 50% 50%;width: 3em;height: 3em;"/></a>   
      <a @click="is_menushow=!is_menushow" title="沉浸式"><Button icon="pi pi-eye" style="margin: 0.1em;position: fixed;right: 1em;bottom: 4.5em;z-index: 97;border-radius: 50% 50%;width: 3em;height: 3em;"/></a>   
      
      <a v-if="login" class="lhide"  @click="relogin" style="cursor: pointer;margin: 0.1em;position: fixed;right: 2.5em;bottom: 28em;z-index: 99;border-radius: 50% 50%;width: 3em;height: 3em;">
        <Knob title="登录过期时间"  :show-value="true" :model-value="time" valueTemplate="{value}%" class="lhide"/>
      </a>
      <a v-if="login" class="lshow" @click="relogin" title="登录续期"><Button icon="pi pi-ticket" style="margin: 0.1em;position: fixed;right: 1em;bottom: 28em;z-index: 97;border-radius: 50% 50%;width: 3em;height: 3em;"/></a>   
      
    </div>

    <div style="height: 2em;"></div>
    <footer class="text-center text-lg-start bg-light text-muted" v-if="is_menushow">
      <div style="max-width: 1280px;margin: auto;">
        <!-- Section: Social media -->
        <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <!-- Left -->
          <div class="me-5 d-none d-lg-block">
            <span>关于硬知识</span>
          </div>
          <!-- Left -->

          <!-- Right -->
          <div>
            <!-- <router-link to="/Blogs" class="me-4 text-reset">
              <i class="fab fa-facebook-f">笔记</i>
            </router-link> 
            <router-link to="/Myself" class="me-4 text-reset">
              <i class="fab fa-twitter">事务</i>
            </router-link> 
            <router-link to="" class="me-4 text-reset">
              <i class="fab fa-google">项目</i>
            </router-link> 
            <router-link to="" class="me-4 text-reset">
              <i class="fab fa-instagram">资源</i>
            </router-link> 
            <router-link to="" class="me-4 text-reset">
              <i class="fab fa-linkedin">广告</i>
            </router-link>  -->

          </div>
          <!-- Right -->
        </section>
        <!-- Section: Social media -->

        <!-- Section: Links  -->
        <section class="">
          <div class="container text-center text-md-start mt-5">
            <!-- Grid row -->
            <div class="row mt-3">
              <!-- Grid column -->
              <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <!-- Content -->
                <h6 class="text-uppercase fw-bold mb-4">
                  <i class="fas fa-gem me-3"></i>碳硅锗兮
                </h6>
                <p>
                  分享资料,原创好内容,传递硬知识,用心做电子产品.
                </p>
              </div>
              <!-- Grid column -->

              <!-- Grid column -->
              <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4" style="text-align: center;">
                
                <div>
                  <div style="height: 30px;">
                    关注公众号！
                  </div>
                  <img class="barcode_photo" alt="碳硅锗兮的关注二维码" src="/img/barcode.jpg" />
                </div>                
              </div>
              <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4" style="text-align: center;">                
                <div>
                  <div style="height: 30px;">
                    赞助硬知识
                  </div>
                  <img class="barcode_photo" alt="碳硅锗兮的赞助二维码" src="/img/Pay_WX.png" />
                </div>
                <div style="height: 20px;"></div>               
              </div>
              <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <!-- Links -->
                <h6 class="text-uppercase fw-bold mb-4">联系方式</h6>
                <p><i class="fas fa-home me-3"></i> 中国.安徽省.宿迁市</p>
                <p>
                  <i class="fas fa-envelope me-3"></i>
                  *****@qq.com
                </p>
                <p><i class="fas fa-phone me-3"></i> +86 180******03</p>
                <p><i class="fas fa-print me-3"></i> +86 180******05</p>
              </div>
              <!-- Grid column -->
            </div>
            <!-- Grid row -->
          </div>
        </section>
        <!-- Section: Links  -->

        <!-- Copyright -->

        <!-- Copyright -->
      </div>
      <div class="text-center p-4">
        访问资源总数：{{useCommon.numtostirng((count.ocount && count.ocount.count) ? count.ocount.count : 0) }},
        访问总人数：{{ useCommon.numtostirng((count.ucount && count.ucount.count) ? count.ucount.count : 0) }},
        注册总人数：{{ useCommon.numtostirng((count.rusers && count.rusers.count) ? count.rusers.count : 0) }}
      <br />
      Copyright @ 2015 <a runat="server" href="/" target="_blank">Fire.Su</a> 版权所有<a href="http://www.miitbeian.gov.cn/"
        target="_blank" rel="nofollow">陕ICP备19023440号</a>
      </div>
    </footer>
  </div>
</template>

<script setup>
import Toast from 'primevue/toast';
import { ref, watchEffect, onMounted } from 'vue';
import store from '@/store';
import { useToast } from 'primevue/usetoast';
import Knob from 'primevue/knob';
import useApi from '@/services/useApi';
import useFree from '@/services/useFree';
import useCommon from '@/services/useCommon';
import Button from 'primevue/button';
const toast = useToast();
const salert = (msg) => {
  if (msg.length > 0)
    toast.add({ severity: 'warn', summary: '提醒', detail: msg, life: 3000 });
};

onMounted(() => {
  get_configs();
})
let count = ref({})
const get_configs = async () => {
  count.value.ocount = await useFree.get_ocount();
  count.value.ucount = await useFree.get_ucount();
  count.value.rusers = await useFree.get_rusers();
}
let is_menushow=ref(true)


const relogin = async () => {
  
  let remaintime = calculate_time();
  console.log(remaintime)
  if (remaintime > 50)
    salert("时间还很充足！");
  else {
    salert("请稍后...");
    await useApi.myuserrelogin();
    time.value = 100;
    calculate_time()
  }
}

const scheck = (msg) => {
  if (msg.length > 0) {
    toast.add({ severity: 'warn', summary: '确定退出小组吗?', detail: 'Proceed to confirm', group: 'global' })

  }
}

let cmune = ref(false);

var myusername = (store.state.username);
var errmsg = store.state.errmsg;
var needcheck = JSON.parse(store.state.needcheck);
var login = store.state.authed
var url = ref('/login')
//let logintime=store.state.logintime



store.commit('reloadLogin');

const scrollToTop = () => {
  window.scrollTo({
    // top: document.documentElement.offsetHeight, //回到底部
    top: 0, //回到顶部
    left: 0,
    behavior: "smooth", //smooth 平滑；auto:瞬间
  });
};

const calculate_time = () => {
  let nowtime = new Date().getTime();
  let logintimes = new Date(localStorage.getItem('logintime'));
  let remaintime = (nowtime - logintimes) / (1000)
  remaintime = parseInt(((1800 - remaintime) / 1800) * 100)
  if (remaintime < 0)
    remaintime = 0;
  if (remaintime > 100)
    remaintime = 100;

  return remaintime
}

let time = ref(calculate_time());
let isstart = ref(false)

const refresh_time = () => {
  let percent = calculate_time()

  if (percent <= 0) {
    isstart.value = false;
    time.value = 0;
  }
  else {
    setTimeout(() => {
      time.value =(time.value-percent<5)?percent:time.value;
      refresh_time();
    }, 15000);
  }
}

var blogurl = ref('/oblogs')

watchEffect(() => {

  myusername = store.state.username
  login = JSON.parse(store.state.authed)
  errmsg = store.state.errmsg;
  if (login) {
    url.value = '/info'
    blogurl.value='/'
    if (!isstart.value) {
      isstart.value = true;
      refresh_time()
    }
  }
  else {
    url.value = '/login'
    blogurl.value='/oblogs'
  }
  if (errmsg.length > 0 && needcheck == false) {
    salert(errmsg);
    localStorage.setItem('errmsg', '');
    store.commit('errAlert')
  }

  if (errmsg.length > 0 && needcheck == true) {
    scheck(errmsg);
    localStorage.setItem('errmsg', '');
    store.commit('errAlert')
  }


})


</script>



<style>
#app {
  font-family: kaiti;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav-link:hover {
  color: #42b983;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.home_left {
  width: 16em;
  float: left;
}
</style>
