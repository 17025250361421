
<template>
    <div>
        <div v-if="sData&&sData.length>0" style="width: 100%;overflow-x: scroll;">
        <DataTable :value="sData" style="min-width: 1024px;">
            <Column field="sCode" header="股票编码" style="width: 5em;font-size: 0.8em;"></Column>                
            <Column field="sName" header="股票名称" style="width: 8em;font-size: 0.8em;"></Column>
            <Column field="sBuyNum" header="持仓数量" style="width: 6em;font-size: 0.8em;"></Column>
            <Column header="总成本" style="width: 5em;font-size: 0.8em;">
                <template #body="slotProps">
                <div>{{ parseFloat(slotProps.data.sBuyPrice* slotProps.data.sBuyNum).toFixed(2)}}</div>
                <div>({{ parseFloat(slotProps.data.sAll*100).toFixed(0)}}%)</div>
                </template>
            </Column>                                    
            <Column header="总收益" style="width: 5em;font-size: 0.8em;">
                <template #body="slotProps">
                <div style="">
                    <div><label :class="slotProps.data.sShouru>0?'up':'down'">{{ parseFloat(slotProps.data.sSalePrice* slotProps.data.sBuyNum).toFixed(2)}}</label></div>
                    <div><label :class="slotProps.data.sShouru>0?'up':'down'" > {{ slotProps.data.sShouru=parseFloat(slotProps.data.sSalePrice* slotProps.data.sBuyNum-slotProps.data.sBuyPrice* slotProps.data.sBuyNum).toFixed(2) }}</label></div>
                    <div><label :class="slotProps.data.sShouru>0?'up':'down'" >{{ parseFloat((slotProps.data.sSalePrice* slotProps.data.sBuyNum-slotProps.data.sBuyPrice* slotProps.data.sBuyNum)*100/(slotProps.data.sBuyPrice* slotProps.data.sBuyNum)).toFixed(2) }}%</label></div>
                </div>
                </template>
            </Column>
            <Column header="持仓成本" style="width: 5em;font-size: 0.8em;">
                <template #body="slotProps">
                <InputText id="companyname" v-model="slotProps.data.sBuyPrice" style="width: 5em;" type="Number"/>
                </template>
            </Column>  
            <Column header="持仓数量" style="width: 5em;font-size: 0.8em;">
                <template #body="slotProps">
                <InputText id="companyname" v-model="slotProps.data.sBuyNum" style="width: 5em;" type="Number" step="100"/>
                </template>
            </Column>
            <Column header="交易价格" style="width: 5em;font-size: 0.8em;">
                <template #body="slotProps">
                <div>
                    <InputText id="companyname" v-model="slotProps.data.sSalePrice" style="width: 5em;" type="Number"/>
                </div>
                </template>
            </Column>
            
            <Column header="交易数量" style="width: 5em;font-size: 0.8em;">
                <template #body="slotProps">
                <div>
                    <InputText id="companyname" v-model="slotProps.data.sSaleNum" style="width: 5em;" step="100" type="Number"/>
                </div>
                </template>
            </Column>
            
            <Column header="状态" style="width: 8em;font-size: 0.8em;">
            <template #body="slotProps">
                <Button icon="pi pi-plus"  style="width: 2em;height: 2em;margin: 0.1em;" title="加仓" @click="async()=>{
                    await useSlade.buy_stock(slotProps.data);
                    listshow();
                    onstockchannge();
                    }" />
                <Button icon="pi pi-minus"  style="width: 2em;height: 2em;margin: 0.1em;" title="减仓" @click="async()=>{ 
                    await useSlade.sale_stock(slotProps.data);
                    listshow();
                    onstockchannge();
                    }" />
                </template>
        </Column>
        </DataTable>
        
        </div>
        <div v-else>
            <div style="padding: 1em;">
                暂未买股票  
            </div>
        </div>
    </div>
  </template>
      
  <script setup>
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import Button from 'primevue/button';
  import InputText from 'primevue/inputtext';
  import useSlade from '@/services/useSlade';
  import { ref, onMounted,watchEffect,defineProps,defineExpose,defineEmits } from "vue";
  const props = defineProps({
    sId:String
  });
  
  let sData=ref({})
  const stockcash=ref({})
  stockcash.value.sBeforeMoney=0
  stockcash.value.sNowMoney=0
  const listshow = async () => {
    let mystock={sBuyStyle:props.sId+''}
      sData.value=await useSlade.get_stocks(mystock)
      sData.value.forEach(async(mystock)=>{
        [mystock.sSalePrice,mystock.sCode]=await getstockprice(mystock.sName)
    })
  }
  defineExpose({ listshow });
  
  onMounted(async() => {
      listshow();
  })
  
  watchEffect(() => {
    props.sId,sData
  })
  
  const getstockprice = async (sname) => { 
    let infostock={}
    infostock.sName=sname
    let info= await useSlade.get_stockinfo(infostock)
    if(info&&info.dplace){
      return [info.当前价格,info.dcode,info.dname]
    }
    return [0,"",sname]
  }


  const emits = defineEmits(['onstockchannge']);
  function onstockchannge() {
      emits("onstockchannge")
  }

  </script>
  
  <style >
  .up{
    font-weight: bold;
    color: red;
  }
  
  .down{
    font-weight: bold;
    color: green;
  }
  
  </style>
    
      