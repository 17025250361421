
<template >    
    <div :id="'blog_show'+props.blog_id">
      <a v-if="blog.canEdit&&isShowAnimation" :title="'编辑'+props.blog_id"><Button icon="pi pi-pencil" style="margin: 0.1em;position: fixed;right: 1em;bottom: 22em;z-index: 99;border-radius: 50% 50%;width: 3em;height: 3em;" @click="blog_edit=~blog_edit"/></a>   
      <a :title="'编辑'+props.blog_id" style="position: absolute;cursor: pointer;top: 0.5em;right: 1em;" @click="blog_edit=!blog_edit">编辑</a>   
      
      
      <div v-if="!blog_edit">
        <div class="padding" style="border-top: 1px rgb(232, 231, 231) solid;margin-top: 1em;">
        <div :innerHTML="blog.bText" style="text-align: left;line-height: 2em;font-family: Arial, Helvetica, sans-serif;" class="line-numbers"></div> 
        <div style="height: 2em;;"></div>
        
        </div>   
        <div v-if="!is_messageshow">
          <div class="padding" v-if="authed"> 
              所有评论
              <CommonPlan :isparent="-1" pstyle="Msg" pheader="评论笔记" :setroledata="setroledata"></CommonPlan>
          </div>
          <div class="padding" v-else>
              登录后查看评论
          </div>
        </div>
      </div>
      <div v-else>
        <div style="padding: 1em;"></div>
         <CommonBlogWrite :blog_id="props.blog_id" :bname="props.bname" :menu_id="props.menu_id"></CommonBlogWrite>
      </div>
      <div class="clear"></div>
  </div>
  </template>
  <script setup>
    import { ref,defineProps,onMounted } from "vue";
    import Button from 'primevue/button';
    import CommonPlan from '@/components/PlanMsg.vue';
    import CommonBlogWrite from '@/components/CommonBlogWrite.vue'
    import useSlade from "@/services/useSlade";
    import useFree from "@/services/useFree";
    import "prismjs/themes/prism-tomorrow.min.css"//引入代码高亮主题（这个去node_modules的安装prismjs中找到想使用的主题即可）
    import "prismjs/plugins/line-numbers/prism-line-numbers.min.js"
    import "prismjs/plugins/line-numbers/prism-line-numbers.min.css"//行号插件的样式
    import Prism from "prismjs"//导入代码高亮插件的core（里面提供了其他官方插件及代码高亮样式主题，你只需要引入即可）
    import { useStore } from "vuex";
    const props = defineProps({        
          blog_id: String,
          is_messageshow: Boolean,
          menu_id:Number,
          bname:String
      })
    onMounted(() => {
      if (props.blog_id && props.blog_id.length > 0) {
        search_blog();
        }
        window.addEventListener("scroll", scrollHandle, true); // 监听 监听元素是否进入/移出可视区域
        console.log("addEventListener scrollHandle")
    })
    const store = useStore();
    let authed = ref(store.state.authed);
  
    let blog=ref({})
    let setroledata=ref({})
    let isShowAnimation=ref(false)
    let blog_edit=ref(false)
    let isShowIcon=ref(false)
    const search_blog=async ()=>{
      blog.value.Id=props.blog_id
      let result={}
      console.log(authed.value)
      if(authed.value)
      {
        result= await useSlade.get_blog(blog.value)}
      else 
      {
        console.log("free")
        result= await useFree.get_blog(blog.value.Id)
      }
      if(result&&result.bId)
      {
        blog.value=result
        setTimeout(function() {
          Prism.highlightAll();
                    }, 1000);
      }
      
    }

    const scrollHandle=()=> {
      var blog_handler=document.getElementById('blog_show'+props.blog_id)
      if(blog_handler)
      {
          const offset = blog_handler.getBoundingClientRect();
          const offsetTop = offset.top;
          const offsetBottom = offset.bottom;
          const limit_bottom=window.innerHeight-50
          const limit_top=300;
          if(offset.height<window.innerHeight)
          {
            isShowIcon.value=true;
          }
          else
          {
            isShowIcon.value=false;
            if (offsetTop <= window.innerHeight && offsetBottom >= limit_bottom && offsetTop <=limit_top) {                
                isShowAnimation.value = true
            } 
            else {
                isShowAnimation.value = false
            }
          }
      }
      else
      {
        window.removeEventListener("scroll", scrollHandle, true); 
        console.log("remove scrollHandle")
      }
  }
     
  </script>

  
  
  