<template>  
    <Dialog v-model:visible="is_upload" header="上传规格书" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"> 
        <datasheet_upload_object @ondatachange="listshow()"></datasheet_upload_object>
    </Dialog>
    <div>
        <TabView>
            <TabPanel header="炒股模拟">
                <StockBuyPanel></StockBuyPanel>   
            </TabPanel>
            <TabPanel header="黑马股票">
                <StockPanel :mydata="heima_stocks"></StockPanel> 
            </TabPanel> 
            <TabPanel header="新晋股票">
                <StockPanel :mydata="xinjin_stocks"></StockPanel>  
            </TabPanel> 
            <TabPanel header="涨停股票">
                <StockPanel :mydata="yzt_stocks"></StockPanel> 
            </TabPanel> 
            <TabPanel header="破板股票">
                <StockPanel :mydata="poban_stocks"></StockPanel> 
            </TabPanel> 
            <TabPanel header="翻车股票">
                <StockPanel :mydata="fanche_stocks"></StockPanel>   
            </TabPanel> 
            
        </TabView>        
    </div>    
</template>
    
<script setup>
import { ref, onMounted } from "vue";
import useSlade from '@/services/useSlade';
import StockPanel from "@/components/stock/StockPanel.vue";
import StockBuyPanel from "@/components/stock/StockPanelBuy.vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Dialog from "primevue/dialog";
import { useRoute } from 'vue-router';

let heima_stocks = ref([])
let xinjin_stocks = ref([])
let yzt_stocks = ref([])
let poban_stocks = ref([])
let fanche_stocks = ref([])
const listshow = async () => {
    xinjin_stocks.value = await useSlade.get_xinjin()
    heima_stocks.value = await useSlade.get_heima()
    yzt_stocks.value = await useSlade.get_steady()
    poban_stocks.value = await useSlade.get_poban()
    fanche_stocks.value = await useSlade.get_fanche()
}


const refresh_time = () => {
    listshow();
    const now = new Date();
    const currentHour = now.getHours();
    const dayOfWeek = now.getDay();
    if((currentHour <= 15 && currentHour >= 9)&&(dayOfWeek<6)){
        setTimeout(() => {
        refresh_time();
        }, 5000);
    }
  
}

const route = useRoute();
onMounted(() => {
    //import { useRoute } from 'vue-router';
    //const route = useRoute();
    useSlade.inject_route(route)
    refresh_time();
    document.title = '[股票测试]-[碳硅锗兮]';
})
</script>
    