<template>
  <div class="card">
    <ProjectList :project_id="props.project_id"></ProjectList>
  </div>
  
</template>

<script setup>
  import ProjectList from '@/components/ProjectList.vue'
  import {defineProps } from "vue";
  const props = defineProps({
      project_id: String
    })

  document.title = '[项目管理]-[碳硅锗兮]';
  document.querySelector('meta[name="keywords"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
  document.querySelector('meta[name="description"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
 </script>

