
<template>
  <div class="about">

    <div :class="oclass">
      <FileUpload mode="basic" name="demo[]" accept="image/*" auto :maxFileSize="2000000"
        :style="{ 'width': '100%', 'height': '15em', 'background-size': 'cover', 'background-image': blog.bImgUrl }"
        customUpload @uploader="customBase64Uploader" />
      <div id="scrollspy-collapsible" style="padding: 0.5em;">
        <Button icon="pi pi-list" @click="openclass()"
          style="margin: 0.1em;position: fixed;right: 1em;bottom: 6em;z-index: 99;" />
        <div class="place1em"></div>


        <div class="card">
          <div style="position: relative;">

          </div>
          <div>
            <div class="card-body">
              <div>
                <div class="div_33_100 margin_top">
                  <div class="p-inputgroup flex-1 ">
                    <span class="p-float-label">
                      <Dropdown v-model="blog.bContent" :options="blogoptions.bContents" option-label="bContent" editable
                        @change="get_bsconts" placeholder="一级目录" class="w-full md:w-14rem" :loading="false" />
                      <Button icon="pi pi-map" @click="$router.replace({ name: 'blogmap' })" title="编辑笔记地图" />
                      <label for="uGangwei">一级目录</label>
                    </span>
                  </div>
                </div>
                <div class="div_33_100 margin_top">
                  <div class="p-inputgroup flex-1 ">
                    <span class="p-float-label">
                      <Dropdown v-model="blog.bsContent" :options="blogoptions.bsContents" option-label="bsContent"
                        @change="get_names" editable placeholder="二级目录" class="w-full md:w-14rem" :loading="false" />
                      <label for="uGangwei">二级目录</label>
                    </span>
                  </div>
                </div>
                <div class="div_33_100 margin_top">
                  <div class="p-inputgroup flex-1 ">
                    <span class="p-float-label">
                      <Dropdown v-model="blog.bName" editable placeholder="笔记标题" max="10" class="w-full md:w-14rem"
                        :options="blogoptions.bNames" option-label="bName" :loading="false" @change="search_blog()" />
                      <Button icon="pi pi-search" title="点击生成笔记ID" @click="search_blog()" />
                      <label for="uGangwei">笔记标题</label>
                    </span>
                  </div>
                </div>
                <div class="div_33_100 margin_top">
                  <div class="p-inputgroup flex-1 ">
                    <span class="p-float-label">
                      <InputText placeholder="笔记ID[根据名字自动生成]" disabled v-model="blog.Id" />
                      <label for="uGangwei">笔记ID[{{ blog.NID }}]</label>
                    </span>
                  </div>
                </div>
                <div class="div_33_100 margin_top">
                  <div class="p-inputgroup flex-1 ">
                    <span class="p-float-label">
                      <InputText placeholder="标签[关键字]" :disabled="false" v-model="blog.bKeyWords" />
                      <label for="uGangwei">标签[关键字]</label>
                    </span>
                  </div>
                </div>

                <div class="div_33_100 margin_top">
                  <div class="p-inputgroup flex-1 ">
                    <span class="p-float-label">
                      <Dropdown v-model="blog.bUrl" :options="blogoptions.bUrls" optionLabel="bUrl" placeholder="文章类型"
                        class="w-full md:w-14rem" :loading="false" />
                      <label for="uGangwei">文章类型</label>
                    </span>
                  </div>
                </div>

                <div class="div_100 margin_top">
                  <div class="p-inputgroup flex-1 ">
                    <span class="p-float-label">
                      <InputText placeholder="描述[SEO]" :disabled="false" v-model="blog.bDescription" />
                      <label for="uGangwei">描述[SEO]</label>
                    </span>
                  </div>
                </div>
                <ObjectShare widthclass="div_33_100" @ondatachange="getrolevalue" :sharedata="getshare"></ObjectShare>
              </div>
            </div>
            <div>
              <Button icon="pi pi-plus" @click="blog.Id = blog.NID; create_blog()" v-if="isnewblog"
                style="margin: 0.1em;position: fixed;right: 1em;bottom: 2em;z-index: 99;" />
              <Button icon="pi pi-refresh" @click="update_blog()" v-if="!isnewblog"
                style="margin: 0.1em;position: fixed;right: 1em;bottom: 2em;z-index: 99;" />
              <Button icon="pi pi-minus" @click="delete_blog()" style="margin: 0.1em;" v-if="!isnewblog" />
              <Button icon="pi pi-video" @click="useApi.jumpto('/blogs/blog_' + blog.Id)"
                style="margin: 0.1em;position: fixed;right: 1em;bottom: 10em;z-index: 99;" />
              <Button icon="pi pi-book" @click="update_blog()" v-if="blog.Id && blog.NID != blog.Id"
                :title="'修改笔记名字' + blog.Id" style="margin: 0.1em;" />
            </div>
            <div style="text-align: left;padding: 1em;">

            </div>
          </div>

        </div>

        <div style="height: 1em;"></div>

      </div>
    </div>
    <div class="div_100">
      <!-- Spied element -->
      <div data-mdb-spy="scroll" data-mdb-target="#scrollspy-collapsible" data-mdb-offset="0" class="scrollspy-example"
        style="padding: 0.5em;">

        <editor v-model="blog.bText" id="EditPanel1" :init="useEditor.init_old" />

      </div>
      <!-- Spied element -->
    </div>
    <div class="clear"></div>


  </div>
</template>

<script setup>
import Editor from '@tinymce/tinymce-vue'
import { ref, defineProps, onMounted } from "vue";
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import useCommon from '@/services/useCommon';
import useApi from '@/services/useApi';
import ObjectShare from '@/components/ObjectShare.vue';
import useSlade from '@/services/useSlade'
import useAlert from '@/services/useAlert';
import useEditor from '@/services/useEditor'

let blog = ref({});
blog.value.bImgUrl = 'url(images/f2.jpg)';
let blogoptions = ref({})
let sendshare = ref({});
let getshare = ref({});
sendshare.value.Id = -1
const getrolevalue = (obj) => {
  sendshare.value = obj
}

const get_configs = async () => {
  blogoptions.value.bUrls = [{ bUrl: "笔记" }, { bUrl: "日记" }, { bUrl: "模版" }]
  blogoptions.value.bContents = await useSlade.get_conts(blog.value);
}

const get_bsconts = async () => {
  let postgroup = useCommon.o_o(blog.value)
  beforepost(postgroup);
  blogoptions.value.bsContents = await useSlade.get_bsconts(postgroup);
}
const get_names = async () => {
  let postgroup = useCommon.o_o(blog.value)
  beforepost(postgroup);
  blogoptions.value.bNames = await useSlade.get_names(postgroup);
}

const beforepost = (obj) => {
  if (obj.bContent)
    obj.bContent = useCommon.o_s(obj.bContent, "bContent")
  if (obj.bsContent)
    obj.bsContent = useCommon.o_s(obj.bsContent, "bsContent")
  if (obj.bUrl)
    obj.bUrl = useCommon.o_s(obj.bUrl, "bUrl")
  if (obj.bName)
    obj.bName = useCommon.o_s(obj.bName, "bName")
}

const create_blog = async () => {
  console.log(blog.value)
  if (blog.value.bContent && blog.value.bsContent && blog.value.bName && blog.value.bKeyWords && blog.value.bDescription) {
    let postgroup = useCommon.o_o(blog.value)
    beforepost(postgroup);
    await useSlade.add_blog(postgroup, sendshare.value);
    search_blog();
  }
  else
    useAlert.salert("信息不得为空")
}

const update_blog = async () => {
  if (blog.value.bContent && blog.value.bsContent && blog.value.bName && blog.value.bKeyWords && blog.value.bDescription) {
    let postgroup = useCommon.o_o(blog.value)
    beforepost(postgroup);
    await useSlade.upd_blog(postgroup, sendshare.value);
    search_blog();
  }
  else
    useAlert.salert("信息不得为空")
}

const delete_blog = async () => {
  let postgroup = useCommon.o_o(blog.value)
  beforepost(postgroup);
  await useSlade.del_blog(postgroup, sendshare);
  search_blog();
}

let isnewblog = ref(true)
let oclass = ref('height_100')
const props = defineProps({
  blog_id: String
})

const search_blog = async () => {
  let postgroup = useCommon.o_o(blog.value)
  beforepost(postgroup);
  let getresult = await useSlade.get_blog(postgroup);
  if (getresult && getresult.bName) {
    getshare.value = getresult
    blog.value = getresult
    isnewblog.value = false;
    blog.value.bUrl = useCommon.ss_o(getshare.value.bUrl, "bUrl")
  }
  else {
    getresult = await useSlade.get_blogid(blog);

    if (getresult && getresult.NID)
      blog.value.NID = getresult.NID
    isnewblog.value = true;
  }

}

onMounted(() => {
  get_configs();
  if (props.blog_id && props.blog_id.length > 0) {
    oclass.value = 'height_35'
    blog.value.Id = props.blog_id.replace('blog_', '');
    search_blog();
  }
  else {
    oclass.value = 'height_100'
  }
  document.title = '[写笔记]-[碳硅锗兮]';
  document.querySelector('meta[name="keywords"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
  document.querySelector('meta[name="description"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
})

const openclass = () => {
  if (oclass.value == 'height_35')
    oclass.value = 'height_100'
  else
    oclass.value = 'height_35'
}

const customBase64Uploader = async (event) => {
  const file = event.files[0];
  let formData = new FormData()
  formData.append('files', file)
  useApi.uploadfile('TinyMCE/Upload', formData).then(res => {
    blog.value.bImgUrl = 'url(' + useApi.myapiurl + res.data.location + ')';
    //blog.value.bImgUrl=blog.value.bImgUrl.replace('//','/')
  })
}
</script>

<style>
/* Styles required only for the example above */
.scrollspy-example-collapsible {
  position: relative;
  height: 200px;
  overflow: auto;
}

.input_box {
  padding: 0.5em 2em 0.5em 2em;
  border-radius: 1em 1em;
  border: 1px silver solid;
  width: 98%;
  margin: 0.5em;
}

.height_35 {
  height: 0em;
}

.height_100 {
  height: auto;
}

</style>
